import { useState, useEffect, useRef } from "react";
import { css } from "@emotion/css";
import styled from '@emotion/styled'
import {overlay, SecondaryButton, GradientButton} from "../styles/GlobalStyles"
import {colors} from "../styles/colors"
// import { useSelector, useDispatch } from 'react-redux';
// import { uploadSyllabus } from "../redux/practiceStateSlice";
import FocusTrap from "focus-trap-react"

import close from "../assets/images/close.svg";
import spinner from "../assets/images/spinner-large.svg";
// import cropIcon from "../assets/images/crop-image.svg";

const ProcessingModal = (props) => {

    const [uploadAvailable, setUploadAvailable] = useState(false)
    const [fileName, setFileName] = useState(null)

    // For displaying the translated image
    const translatedImage = props.translatedImage;
    const setTranslatedImage = props.setTranslatedImage;

    const stopBubble = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return(
        <>
            <FocusTrap>
            <div className={uploadModal}>
              <button
                className={closeButton}
              >
                <img src={close}/>
              </button>

              <p className={modalHeader}>Processing…</p>

              <img src={spinner} className={spin}/>

            </div>  
            </FocusTrap>
            <div className={overlay}></div>
        </>
       
    )
}   

export default ProcessingModal

const closeButton = css`
    position:absolute;
    top:24px;
    right:24px;
`

const spin = css`
  width: 50px;
  height: auto;
`;

const uploadModal = css`
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        min-width:60vw;
        max-width: 80vw;
        // max-height: 80vh;
        min-height: 10vh;
        padding: 32px 32px 48px;
        top: 50%;
        left: 50%;
        right: unset;
        transform: translate(-50%, -50%);
        background-color:${colors.grayLight3};
        border-radius:12px;
        z-index:3001;
        color:${colors.textlightThemeDefault};
    `

const modalHeader = css`
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    padding: 0;
    margin: 16px 0 32px;
    flex-grow: 0;
`

const imgContainer = css`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    flex-grow: 1;
`

const uploadImage = css`
    border: 3px solid #F00;
`

const CropButton = styled(SecondaryButton)`
    border-color: ${colors.darkGray};
    color: ${colors.textlightThemeDefault};
    gap: 8px;
    // width:100px;
    font-weight: 400;
    margin:0;
    &:focus{
        outline-color: black;
        outline-offset: 5px;
        outline-style: solid;
    }
`;
