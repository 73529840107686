/** @format */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import UploadNotes from "../components/UploadNotes";
import ProcessingModal from "../components/ProcessingModal";

const Upload = () => {

  const [showProcessingModal, setShowProcessingModal] = useState(false);

  return (
    <>

      {
        showProcessingModal ?
          <ProcessingModal/> : null
      }

      <h1 style={{marginLeft: '24px'}}>AI Notes</h1>
      <Link style={{marginLeft: '24px', marginBottom: '24px', display: 'block'}} to={`${process.env.PUBLIC_URL}/final`}>Go to My AI Notes</Link>
      <UploadNotes
        showProcessingModal = {showProcessingModal}
        setShowProcessingModal = {setShowProcessingModal}
      />
      {/*<Link to={`${process.env.PUBLIC_URL}/review`}>Go to Review</Link>*/}
    </>
  );
}
export default Upload;
