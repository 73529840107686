/** @format */
import { Link } from "react-router-dom";
import styled from '@emotion/styled'

import botIcon from "../assets/images/bot.svg"

const Final = (props) => {

  return (
    <FinalWrapper>
      <h1>My AI Notes</h1>
      <Link style={{marginBottom: '24px', display: 'block'}} to={`${process.env.PUBLIC_URL}/`}>Return Home</Link>
      <NoteCard>
          <h2>
            <img class="bot-icon" src={botIcon}/>
            Lecture notes: Sept 9th, 2024
          </h2>
          <p></p>
      </NoteCard>
    </FinalWrapper>

  );
}
export default Final;

const FinalWrapper = styled.div`
  padding:24px;
`

const NoteCard = styled.div`
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  width: fit-content;
  min-height: 104px;
  padding: 12px 16px;
  margin: 0px;
  overflow: hidden;
  transition: border 0.25s;
  background-color: rgb(254, 254, 254);
  border-color: rgb(188, 193, 203);
  cursor:pointer;
  &:hover{
    background-color:#E8EEFA;
  }
  h2{
    display:flex;
    align-item:center;
    gap:16px;
  }
`
