import { Route, Routes } from "react-router-dom";
import { css } from '@emotion/css';

// Components
import Header from './components/header';
import Nav from './components/nav';
import EditNotes from "./components/EditNotes";

// Views
import Home from "./views/Home"
import Upload from './views/Upload';
import Review from './views/Review';
import Final from './views/Final';

function App() {
  return (
    // <main>
    //   <Header />
    //   <div className={gridStyles}>
    //     <Nav/>
        <div className={mainContent}>
           <Routes>
            <Route path={process.env.PUBLIC_URL + "/"} element={<Home/>} />
            <Route path={process.env.PUBLIC_URL + "/upload"} element={<Upload/>} />
            <Route path={process.env.PUBLIC_URL + "/review"} element={<Review/>} />
            <Route path={process.env.PUBLIC_URL + "/final"} element={<Final/>} />
          </Routes>
        </div>
    //   </div>
    // </main>
  );
}

const gridStyles = css`
  display:flex;
`;

const mainContent = css`
  // padding: 20px;
  // flex-grow: 1;
  background-color: #FFF;
  color: #000;
`;

export default App;
