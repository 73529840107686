import { useState } from "react";
import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'
import Header from "../components/AHHeader"
import ETextContainer from "../components/ETextContainer";
import ChannelAssets from "../components/ChannelAssets";
import Navigation from "../components/Navigation";
import AccordionCardInner from '../components/AccordionCard';
import { useNavigate } from "react-router-dom";

import illustration from "../assets/images/mojo.png"
import botIcon from "../assets/images/bot.svg"

const breakpoints = {
  mobile: 360,
  tablet: 768,
  laptop: 1024,
  desktop: 1280
}

function Home () {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)


  return(
    <HomeWrapper>
    <Header/>
    <PageBody>
        <Navigation />
        {
        loading ?
          // <Loading/>
          <>loading</>
          :
          <>
              <Main>
                  <h1>Campbell Biology, 12e</h1>
                  <Layout>
                    <MainColumn>
                          <div
                            data-tour-step="3"
                          >
                            <Section>
                              <ChannelAssets/>
                            </Section>
                            <Section
                              // className={!course.syllabusUploaded ? "exam-illustration" : ""}
                              >
                                <h3 className='examh3'>Exam schedule</h3>
                                {/* {
                                  !course.syllabusUploaded ? */}
                                    <ExamUploadCopy>
                                      <p>Upload your syllabus to get the most out of your <b>Study & Exam Prep Pack.</b></p>
                                      <p>Get recommendations on what to study and when.</p>
                                      <p>No syllabus? Sharing your exam schedule works too.</p>
                                    </ExamUploadCopy>
                                    {/* : null
                                } */}
                                  {/* <>
                                    <AddAnExam
                                      uploadAvailable={uploadAvailable}
                                      fileName={fileName}
                                      setUploadAvailable={setUploadAvailable}
                                      setFileName={setFileName}
                                      handleChangeMode={handleChangeMode}
                                    />
                                    {
                                      mode === 'upload' ?
                                      <button
                                        className={cx(PrimaryButton, UploadButton)}
                                        disabled={!uploadAvailable ? true : undefined}
                                        onClick={handleUpload}
                                      >
                                        Upload
                                      </button>
                                      : null
                                    }
                                  </> */}
                              </Section>
                          </div>
                    </MainColumn>
                    <Sidebar>
                          <EtextSection>
                            <ETextContainer/>
                          </EtextSection>
                          <Section>
                              <AINotesButton
                                onClick={()=>{
                                  navigate(`${process.env.PUBLIC_URL}/upload`);
                                }}
                              >

                                    <img src={botIcon}/>
                                    AI Notes

                              </AINotesButton>
                          </Section>
                          <AccordionCard data-tour-step="5">
                            <AccordionCardInner />
                          </AccordionCard>
                        <Section>
                          <h3 className='substatush3'>Pearson+ subscription status</h3>
                          <SubStatus>
                            {/* {
                              subStatus.length > 0 ?
                                subStatus.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      <img src={check} />
                                      <span>{item}</span>
                                    </li>
                                  )
                                })
                                : null
                            } */}
                          </SubStatus>
                        </Section>
                    </Sidebar>
                  </Layout>
                </Main>
          </>
        }
      </PageBody>
      </HomeWrapper>
  )
}

export default Home


const AINotesButton = styled.button`
  color:white;
    display:flex;
    align-items:center;
    gap:16px;
    width:100%;
    &:hover {
        // background-color:#272D39;
        img {opacity:1;}

        &:after {
            background: linear-gradient(135deg, #c08529, #b41863 51.09%, #851b4a) border-box;
            border: 2px solid transparent;
            border-radius: inherit;
            border-radius: 12px;
            bottom: 0;
            content: "";
            left: 0;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            position: absolute;
            right: 0;
            top: 0;
            z-index:0;
        }
  }
`

const HomeWrapper = styled.div`
  width:100vw;
  min-height:100vh;
  background-color: rgb(2, 9, 23);
  color: white;
  h1{
    color:white;
  }
`

const Main = styled.main`
  // border-radius: 24px;
  color:black;
  // padding:24px 16px;
  margin-top:32px;
  h1, h2{
    margin:0;
  }
  h2{
    font-weight: 400;
    color: var(--Text-darkTheme--Muted, #989BA3);
    font-size: 22px;
    font-weight: 500;
  }
`

const Layout = styled.div`
  display:flex;
  gap:32px;
  align-items:flex-start;
  justify-content:space-between;
  margin-top:32px;
  @media(max-width: ${breakpoints.laptop}px){
    gap:16px;
  }
  @media(max-width: ${breakpoints.tablet}px){
    display:block;
  }
`

const MainColumn = styled.div`
  flex:1;
  max-width:1098px;
`

const Sidebar = styled.div`
  max-width:534px;
  min-width:320px;
  overflow:hidden;
  flex:1;
  @media(max-width: ${breakpoints.desktop}px){
    max-width:394px;
  };
  @media(max-width: ${breakpoints.laptop}px){
    max-width:320px;
  };
  @media(max-width: ${breakpoints.tablet}px){
    max-width:unset;
    width:100%;
  };
  &>img{
    width: 208px;
    height: 247px;
    background: #D9D9D9;
  }
`

const Section = styled.section`
  width:100%;
  border-radius: 16px;
  background: #161C29;
  color: #FEFEFE;
  padding: 24px;
  margin-bottom:32px;
  position:relative;
  @media(max-width:${breakpoints.laptop}px){
    margin-bottom:16px;
    padding:16px;
  }
  h3 {
    color: #FEFEFE;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.667%;
    margin: 0;
    letter-spacing: 0.4px;
    // margin-bottom: 16px;
    &.examh3, &.substatush3{
      margin-bottom:24px;
    }
  }

  .section-title, .section-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.exam-illustration{
    background-image:url(${illustration});
    background-position:top right;
    background-repeat:no-repeat;
  }
`

const EtextSection = styled(Section)`
padding: 24px;

  .section-title {
    margin-bottom: 16px;

    .actions {
      display: flex;
      gap:8px;
      button {

        &.info {
          position: relative;

          // &:after {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   right: 0;
          //   width: 6px;
          //   height: 6px;
          //   border-radius: 50%;
          //   background: #EA0B7E;
          // }
        }
      }
    }
  }
  h3{
    font-size: 24px;
    font-weight: 700;
    color: var(--Text-darkTheme--Default, #FEFEFE);
    margin: 0;
  }

  &>div{
    display:flex;
    justify-content:flex-start;
    gap:16px;
    @media(max-width:${breakpoints.laptop}px){
        display:block;
    }
    h4{
      margin: 0;
      overflow: hidden;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 133.333%;
      letter-spacing: 0.4px;
      max-width:200px;
    }
  }
`

const ExamUploadCopy = styled.div`
  p{
    margin:0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    &:first-of-type{
      margin-bottom: 16px;
    }
    &:last-of-type{
      margin-bottom: 36px;
    }
  }
`

const UploadButton = css`
  margin: 24px auto 0 auto;
`

const Exams = styled.div`
  max-height:320px;
  overflow:auto;
`

const ExamContainer = styled.div`
  border-radius: 12px;
  border: 0px solid var(--Border-darkTheme--border, #393F4A);
  background: var(--background-dark-theme-bg-level-2, #272D39);
  width:100%;
  height: 80px;
  text-align:left;
  margin-bottom:16px;
  padding:16px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  gap:24px;
  border: 2px solid transparent;
  &:hover{
    background: linear-gradient(#272D39, #272D39) padding-box, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%) border-box;
  }
  &>div{
    display:flex;
    align-items:center;
    justift-content:space-between;
    gap:24px;
  }
`

const ExamProgress = styled.div`
  height:48px;
  width:48px;
  display:flex;
  align-items:center;
  justify-content:center;
  position:relative;
  span{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    font-size: 14px;
    font-weight: 700;
    color: #FFF;
    letter-spacing: 0.24px;
  }
`

const ExamNameDate = styled.div`
  color: var(--Text-darkTheme--Default, #FEFEFE);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ExamName = styled.span`
  color: var(--Text-darkTheme--Default, #FEFEFE);
  font-size: 18px;
  font-weight: 700;
  margin-right:16px;
`

const ExamDate = styled.span`
  color: var(--Text-darkTheme--Muted, #989BA3);
  font-size: 14px;
  font-weight: 400;
`

const ExamChapters = styled.div`
  margin-top:4px;
  color: var(--Text-darkTheme--Muted, #989BA3);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  span{
    color: var(--Text-darkTheme--Muted, #989BA3);
    font-size: 16px;
    font-weight: 700;
  }
`

const ExamCTA = styled.button`
  color: var(--Text-darkTheme--Default, #FEFEFE);
  font-size: 18px;
  font-weight: 700;
  @media(max-width:${breakpoints.mobile}px){
    display:none;
  }
`



const EtextCover = styled.div`
  border-radius: 12px;
  background: var(--background-dark-theme-bg-level-2, #272D39);
  min-width:160px;
  height:208px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex:0;
  img{
    width: 144px;
    height: 192px;
    border-radius: 8px;
    background: #D9D9D9;
  }
`

const GoToPage = styled.div`
  color:white;
  gap:8px;
  width:100%;
  label{
    white-space: nowrap;
    color: #989BA3;
    margin-top:8px;
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 171.429%;
  }
  input{
    border-radius: 8px;
    border: 2px solid #393F4A;
    background: #020917;
    padding: 12px 8px 12px 16px;
    min-width: 0;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    color: #989BA3;
  }
`

const EtextCTAs = styled.div`
  flex: 1;

  ul{
    display: flex;
    flex-direction: column;
    gap: 8px;

    li{
      button{
        width:100%;
        border-radius: 12px;
        background: #272D39;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 16px;
        height: 56px;

        span{
          color: #FEFEFE;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
`


const ChannelContainer = styled(Section)`
    padding:24px 30px;
    h3{
      font-size: 24px;
      font-weight: 700;
      color: var(--Text-darkTheme--Default, #FEFEFE);
      margin:0;
    }
      .section-title {
      margin-bottom:16px;
    }
    &>div{
      display:flex;
      gap:16px;

      h4{
        margin:0;
        font-size:18px;
      }
    }
`

const ChannelThumbnail = styled.div`
  border-radius: 12px;
  background: var(--background-dark-theme-bg-level-2, #272D39);
  width:160px;
  height:208px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex:0;
  padding:8px;
  @media(max-width: ${breakpoints.laptop}px){
    width:120px;
    height:156px;
  };
  img{
    width: 144px;
    height: 192px;
    object-fit: cover;
    border-radius: 8px;
    background: #D9D9D9;
    @media(max-width: ${breakpoints.laptop}px){
      width:104px;
      height:140px;
    }
  }
`

const ChannelInfo = styled.div`
  button{
    margin-top:16px;
  }
`
const MLMButton = css`
width:100%;
`

const AccordionCard = styled(Section)`
  padding:24px;
`

const SubStatus = styled.ul`
  li{
    display:flex;
    align-items:center;
    gap:16px;
    margin-bottom:8px;
    font-size: 18px;
    line-height: 24px;
    &:last-of-type {
      margin-bottom:0;
    }
    img{
      width:16px;
      height:16px;
    }
  }
`

const StudyHelpHeader = css`
  // position:absolute;
  padding-right:120px;
  // top:24px;
  // left:24px;
  margin:0;
  margin-bottom: -32px !important;
`
// const StudyCarousel = styled(CourseCarousel)`
//   margin-top:0;
//   margin-bottom:0;
// `

export const PageBody = styled.div`
  padding:16px 64px 32px 64px;
  max-width:1664px;
  margin:0 auto;
  @media(max-width: ${breakpoints.laptop}px){
    padding:16px 16px 32px 16px;
  }
  @media(max-width: ${breakpoints.mobile}px){
    padding:8px;
  }
`

export const Modal = styled.div`
    border-radius: 16px;
    background: var(--background-dark-theme-bg-level-1, #161C29);
    box-shadow: 0px 0px 32px 0px #020917;
    color: var(--Text-darkTheme--Default, #FEFEFE);
    padding:0px;
    width: 80vw;
    max-width:960px;
    height: 640px;
    overflow:hidden;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:113;
    display:flex;
    flex-direction:column;

    @media (max-height:660px) {
        min-height:0;
        max-height:80vh;
    }
`

export const Overlay = styled.div`
    width:100vw;
    height:100vh;
    background:black;
    opacity:0.8;
    z-index:112;
    position:absolute;
    top:0;
    left:0;
`

export const OutlineButton = css`
    border-radius: 40px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 6px 24px;
    width:fit-content;
    border: 2px solid var(--Swatch-Light-400, #D2D7E2);
    color: var(--Text-darkTheme--Default, #FEFEFE);
    font-size: 18px;
    line-height:24px;
    font-weight: 700;
    &[disabled]{
        opacity:0.32;
    }
        &:hover {
            border: 2px solid #F7F9FD;
            background-color:#F7F9FD;
            color:#020917;
        }
        &:focus {
            background-color:#F7F9FD;
            border:2px dashed #393F4A;
            color:#020917;
        }
    &.dark {
        border-color:#272D39;
        color:#272D39;
        &:hover {
            background-color:#393F4A;
            border-color:#393F4A;
            color:#fefefe;
        }
        &:focus {
            background-color:#393F4A;
            border:2px dashed #DDE3EE;
            color:#fefefe;
        }
    }

`

export const FillButton = css`
    border-radius: 40px;
    background: var(--Swatch-Light-400, #D2D7E2);
    border:2px solid transparent;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
    width:fit-content;
    color: #020917;
    font-size: 18px;
    line-height:24px;
    font-weight: 700;
    &[disabled]{
        opacity:0.32;
    }
        &:focus {
            background-color:#F7F9FD;
            border:2px dashed #DDE3EE;
        }

    &.dark {
        border-color:#272D39;
        background-color:#272D39;
        color:#fefefe;
        &:hover {
            background-color:#393F4A;
            border-color:#393F4A;
            color:#fefefe;
        }
        &:focus {
            background-color:#393F4A;
            border:2px dashed #DDE3EE;
        }
    }
`
export const SmallButton = css`
    font-size:14px;
    line-height:16px;
    padding: 8px 16px;
`

export const PrimaryButton = css`
    border-radius: 40px;
    background: var(--Gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
    color: var(--Text-darkTheme--Default, #FEFEFE);
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
    width:fit-content;
    height:40px;
    font-size: 18px;
    font-weight: 700;
    white-space:nowrap;

    &[disabled]{
        opacity:0.32;
    }
        &:hover {
            background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
        }
`
export const LinkButton = css`
    border:none;
    background:transparent;
    color: #655591;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: underline;
    &:hover, &:focus {
        text-decoration:none;
    }
`
export const IconButton = css`
    width:40px;
    height:40px;
    border-radius:50%;
    padding:10px;
    background: transparent;
    color:#fefefe;
    border:2px solid transparent;
    display:flex;
    align-items:center;
    justify-content:center;

    &:hover {
        background:#272D39;
    }
    &:active {
        border: 2px dashed #DDE3EE;
    }
`
export const ModalHeader = styled.h1`
  width:100%;
  text-align:left;
  font-size: 40px;
  font-weight: 700;
  padding:40px 40px 24px 40px;
  margin:0;
`

export const ModalActions = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-end;
  gap:16px;
  width:100%;
  padding: 24px 16px;
`
export const Tooltip = css`
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    background-color:#fefefe;
    color:#020917;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
`
