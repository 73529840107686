import { useState } from "react";
import styled from '@emotion/styled'
import {ai_output_notes} from "../data/ai_output_notes"
import '../styles/video_cards.css'
import { cx, css } from '@emotion/css'
import { Link } from "react-router-dom";

import botIcon from "../assets/images/bot.svg"
import thumbsUp from "../assets/images/u_thumbs-up.svg"
import thumbsUpFilled from "../assets/images/u_thumbs-up-filled.svg"
import thumbsDown from "../assets/images/u_thumbs-down.svg"

function EditNotes() {

    const [finalNotes, setFinalNotes] = useState([...ai_output_notes])
    const [tempDeleteNotes, setTempDeleteNotes] = useState([])
    const [editMode, setEditMode] = useState(true)

    const handleTempDeleteOrKeep = (id) => {
        let newNotesArray = [...finalNotes]
        newNotesArray.forEach((node,index)=>{
          if(node.id === id){
            if(!node.markedForDelete){
              node.markedForDelete = true
              setTempDeleteNotes(prevState => [...prevState, id])
            }else{
                delete node.markedForDelete
                setTempDeleteNotes(prevState => {
                    let newArray = [...prevState]
                    return newArray.toSpliced(newArray.indexOf(id), 1)
                })

            }
          }
        })
        setFinalNotes(newNotesArray)
    }

    const handleSaveNotes = () => {
      let processedNotes = [...finalNotes]
      // processedNotes.forEach((node, i)=>{
      //   if(node.markedForDelete){
      //     processedNotes.splice(processedNotes.indexOf(node), 1)
      //   }
      // })
      processedNotes = processedNotes.filter(node => {
        if(!node.markedForDelete || node.markedForDelete != true){
          return node
        }
      })
      setFinalNotes(processedNotes)
      setEditMode(false)
      window.scrollTo(0,0)
    }

    const handleThumbsUp = (id) => {
      let newNotesArray = [...finalNotes]
      newNotesArray.forEach((node,index)=>{
        if(node.id === id){
          if(!node.thumbsUp){
            node.thumbsUp = true
          }else{
              delete node.thumbsUp
          }
        }
      })
      setFinalNotes(newNotesArray)
    }

    return (
        <>
        {
          editMode ?
          <h1 style={{marginLeft: '24px'}}>Review AI Suggestions</h1>
          :
          <Link style={{marginLeft: '24px', marginTop: '24px', display: 'block'}} to={`${process.env.PUBLIC_URL}/final`}>Go to My AI Notes</Link>
        }
        <NotesEditor>
            <NotesEditorBody>
                {
                    finalNotes ? finalNotes.map((node, i)=>{
                        return(
                              <Node
                                  key={i}
                                  data-id={node.id}
                                  className={`
                                    ${node.video ? 'video' : ''}
                                    ${editMode ? 'editMode' : ''}
                                    ${node.editable ? 'editable' : ''}
                                    ${tempDeleteNotes.includes(node.id) ? 'marked-for-delete' : ''}
                                  `}

                              >
                                  <NodeContainer>
                                  {
                                      node.editable && editMode ?
                                      <img class="bot-icon" src={botIcon}/>
                                      : null
                                  }
                                  <span dangerouslySetInnerHTML={{ __html: node.content }}></span>
                                  {
                                      node.editable && editMode === true?
                                      <div className="node-actions">
                                            {
                                              !node.markedForDelete ?
                                              <>
                                                <button
                                                  className="thumb thumbs-up"
                                                  onClick={()=>{handleThumbsUp(node.id)}}
                                                >
                                                    <img src={node.thumbsUp === true ? thumbsUpFilled  : thumbsUp}/>
                                                </button>
                                                  <button
                                                  className="thumb thumbs-down"
                                                  onClick={()=>{handleTempDeleteOrKeep(node.id)}}
                                                >
                                                    <img src={thumbsDown}/>
                                                </button>
                                              </>
                                               : null
                                            }
                                            {node.markedForDelete ?
                                              <button
                                                className="undo"
                                                style={{display:'flex', gap: '8px'}}
                                                onClick={()=>{handleTempDeleteOrKeep(node.id)}}
                                              >
                                                  <img src={thumbsUp}/>
                                                  <span>Undo</span>
                                              </button>
                                              :
                                            null
                                          }
                                      </div>
                                      : null
                                  }
                                  </NodeContainer>
                              </Node>
                        )
                    })
                    : null
                }
            </NotesEditorBody>
            <NotesEditorFooter>
              {
                editMode ?
                <button
                  className={PrimaryButton}
                    onClick={handleSaveNotes}
                  >
                    Save
                </button>
                : null
              }

            </NotesEditorFooter>
        </NotesEditor>
        </>
    );
}

export default EditNotes;

const NotesEditor = styled.div`
// border: 1px solid blue;
`

const NotesEditorBody = styled.div`
    text-align:left;
    padding:30px;
    // border: 1px solid red;
`

const NodeContainer = styled.div`
  position:relative;
  .thumb{
    width:24px
    height:24px;
  }
`

const Node = styled.div`
    padding:6px;
    margin:0;
    margin-bottom:30px;
    border-radius:8px;
    &.video{
      // float:left;
      margin-right:24px;
      display:inline-block;
    }
    // &:not(.video){
    //   clear:both;
    // }
    h1, h2, h3, h4, p{
      margin:0;
    }
    ul{
      padding-inline-start:32px;
    }
    .bot-icon{
        position:absolute;
        top:-24px;
        left:-20px;
    }
    .node-actions{
        // display:none;
        margin: 8px;
        img{
          width:20px;
          height:20px;
        }
    }
    &.editMode.editable{
        background-color:#E8EEFA;
    }
    &.marked-for-delete{
      background-color:rgb(247 0 0 / 46%) !important;
      // opacity:0.5;
    }
    &.editMode.editable:hover{
        cursor:pointer;
        background-color:#d6dff1;
        .node-actions{
            display:block
        }
    }

`

const NotesEditorFooter = styled.div`
    padding: 30px;
    // position:fixed;
    // bottom: 0;
    width:100%;
    background-color:white;
    box-shadow: 1px 3px 10px 0px rgba(213, 219, 232, 0.64);
`

export const PrimaryButton = css`
    border-radius: 40px;
    background: var(--Gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
    color: var(--Text-darkTheme--Default, #FEFEFE);
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
    width:fit-content;
    height:40px;
    font-size: 18px;
    font-weight: 700;
    white-space:nowrap;

    &[disabled]{
        opacity:0.32;
    }
        &:hover {
            background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
        }
`
