import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { useNavigate, useLocation, createSearchParams, useSearchParams } from 'react-router-dom'
// import { breakpoints } from '../../global/global_breakpoints';
// import { TitleModalProps } from '../../global/global_types';

import logo from "../assets/images/pearson_logo.svg"
import qrIcon from "../assets/images/qrcode.svg"
import dropDownIcon from "../assets/images/chevron.svg"
import searchIcon from '../assets/images/search-24.svg';
import mobileMenu from "../assets/images/mobile-menu-24.svg"

const breakpoints = {
  mobile: 360,
  tablet: 768,
  laptop: 1024,
  desktop: 1280
}

export default function Header() {

    const location = useLocation()
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showAllCourses, setShowAllCourses] = useState(false)

    useEffect(() => {
        if (searchParams.get('showAllCourses') === 'true') {
            setShowAllCourses(true)
        }
    }, [searchParams])

    return (
        <HeaderContainer >
            <div className='left'>
                <MobileMenu>
                    <img src={mobileMenu}/>
                </MobileMenu>
                <Logo>
                    <img
                        src={logo} alt="Pearson"
                        // onClick={()=>{
                        //     navigate({
                        //         pathname: `${process.env.PUBLIC_URL}/course/0`,
                        //         search: showAllCourses ? createSearchParams({
                        //             showAllCourses: "true"
                        //         }).toString() : ""
                        //     })
                        // }}
                    />
                </Logo>
                <Search>
                    <input type="text" placeholder="Search by ISBN, Title, or Author"/>
                    <img src={searchIcon} alt=""/>
                </Search>
            </div>
            <div className='right'>
                <HeaderLinkButton
                    // onClick={()=>{
                    //     navigate({
                    //         pathname: `${process.env.PUBLIC_URL}/more`,
                    //         search: showAllCourses ? createSearchParams({
                    //             showAllCourses: "true"
                    //         }).toString() : ""
                    //     })
                    // }}
                >
                    More at Pearson
                </HeaderLinkButton>
                <HeaderLinkButton
                    // onClick={()=>{
                    //     navigate({
                    //         pathname: `${process.env.PUBLIC_URL}/library`,
                    //         search: showAllCourses ? createSearchParams({
                    //             showAllCourses: "true"
                    //         }).toString() : ""
                    //     })
                    // }}
                >
                    My eTextbooks
                </HeaderLinkButton>
                <HeaderButton
                    data-tour-step="9"
                >
                    <img src={qrIcon} alt=""/>
                    <span>Continue in the app</span>
                </HeaderButton>
                <MobileSearch>
                    <img src={searchIcon} alt=""/>
                </MobileSearch>
                <Avatar data-tour-step="1">
                    <span>SD</span>
                    <img src={dropDownIcon} alt=""/>
                </Avatar>
            </div>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.header`
    width:100%;
    height:80px;
    background: #020917;
    color: var(--Text-darkTheme--Default, #FEFEFE);
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0px 32px;
    gap:32px;
    position:sticky;
    z-index: 111;
    top:0;
    &>div{
        display:flex;
        align-items:center;
        gap:24px;
        height:100%;
        &.left{
            width:100%;
        }
        @media(max-width: ${breakpoints.laptop}px){
            gap:16px;
         }
    }
    @media(max-width: ${breakpoints.laptop}px){
       gap:16px;
       padding:0px 12px;
    }
`

const MobileMenu = styled.button`
    display:none;
    @media(max-width: ${breakpoints.tablet}px){
        display:block;
    }
`

const Logo = styled.button`
    cursor:pointer;
`
const Search = styled.div`
    position:relative;
    color: var(--Text-darkTheme--Default, #FEFEFE);
    flex:1;
    @media(max-width: ${breakpoints.laptop}px){
        flex:0;
    }
    @media(max-width: ${breakpoints.tablet}px){
        display:none
    }
    input {
        border: 2px solid var(--Text-darkTheme--Default, #FEFEFE);
        min-height:36px;
        background:transparent;
        border-radius:22px;
        width:100%;
        padding: 12px 16px 12px 48px;
        font-size:16px;
        color:#fefefe;
        &::placeholder {
            color:#F7F9FD;
        }
        &:placeholder-shown {
            text-overflow: ellipsis;
        }
        @media(max-width: ${breakpoints.laptop}px){
            display:none
        }
    }
    img {
        position:absolute;
        left:16px;
        top:50%;
        transform:translateY(-50%);
        @media(max-width: ${breakpoints.laptop}px){
            position:relative;
            left:unset;
            top:unset;
            transform:unset;
        }
    }
`

const MobileSearch = styled.button`
    display:none;
    @media(max-width: ${breakpoints.tablet}px){
        display:block;
    }
`

const HeaderLinkButton = styled.button`
    font-size: 18px;
    line-height:24px;
    color: var(--Text-darkTheme--Default, #FEFEFE);
    font-weight: 700;
    position:relative;
    height:100%;
    padding:12px 0;
    white-space:nowrap;
    &:after {
        content:'';
        height: 4px;
        border-radius:2px;
        width:100%;
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        transition:background-color 0.3s ease;
    }
    &:hover {
        &:after {
            background-color:#DDE3EE;
        }
    }
    @media(max-width: ${breakpoints.tablet}px){
        display:none;
    }
`
const headerLinkActive = css`
    &:after {
        background: var(--Gradient-01--Hover, linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%));
    }
`

const HeaderButton = styled.button`
    border-radius: 48px;
    border: 2px solid var(--Swatch-Light-400, #D2D7E2);
    color: var(--Text-darkTheme--Default, #FEFEFE);
    display:flex;
    align-items:center;
    justiy-content:center;
    gap:8px;
    height:40px;
    padding: 0 24px 0 16px;
    margin-left:8px;
    white-space:nowrap;
    span{
        font-size: 18px;
        font-weight: 700;
    }
    @media(max-width: ${breakpoints.laptop}px){
        margin-left:0;
    }
    @media(max-width: ${breakpoints.tablet}px){
        display:none;
    }
`

const Avatar = styled.button`
    display:flex;
    align-items:center;
    gap:4px;
    padding:0;
    span{
        border-radius: 48px;
        border: 2px solid var(--background-light-theme-bg-level-0, #FEFEFE);
        color: var(--Text-darkTheme--Default, #FEFEFE);
        width:40px;
        height:40px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size: 14px;
        font-weight: 600;
    }
`

