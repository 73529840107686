export const channelAssets = [
  {
  _id
  :
  "65ae96170e075b6be10f9cf7",
  title
  :
  "Introduction to Biology",
  type
  :
  "Intro",
  url
  :
  "https://b-cdn-video.clutchprep.com/videos/Channels-Intro-Video-General-Biology-vFINAL__720p__1694423342846.mp4",
  thumbnailUrl
  :
  "https://static.studychannel.pearsonprd.tech/courses/biology/thumbnails/8ba0634b",
  duration
  :
  88,
  order
  :
  1
  },
  {

    _id
    :
    "65afd2ac0e075b6be10f9d0d",
    duration
    :
    174,
    thumbnailUrl
    :
    "https://static.studychannel.pearsonprd.tech/courses/biology/thumbnails/5757c3f0",
    title
    :
    "Introduction to Biology",
    type
    :
    "Concept",
    url
    :
    "https://b-cdn-video.clutchprep.com/videos/Biology-1-1-22S-Introduction-To-Biology-C-1-V-2-final__720p__1656640371133.mp4",
    order
    :
    2
  },
  {
  _id
  :
  "65b90ad18cd0c30680a730b3",
  type
  :
  "Problem",
  question
  :
  "The area of science that studies life and its processes is called:",
  title
  :
  "Introduction to Biology",
  order
  :
  3
  },
  {

_id
:
"65afda010e075b6be10f9d16",
duration
:
129,
thumbnailUrl
:
"https://static.studychannel.pearsonprd.tech/courses/biology/thumbnails/113f9094",
title
:
"Unicellular vs. Multicellular",
type
:
"Concept",
url
:
"https://b-cdn-video.clutchprep.com/videos/Biology-1-1-22S-Introduction-To-Biology-C-2-V-2-final__720p__1656640650561.mp4",
order
:
4
  }
]
