import { Tooltip } from 'react-tooltip'

function Tooltips(props){
    return (
        <>
            <Tooltip anchorSelect=".thumbs-up" place="top" className='custom-tooltip'>
                This is helpful
            </Tooltip>
            <Tooltip anchorSelect=".thumbs-down" place="top" className='custom-tooltip'>
                Remove
            </Tooltip>
            <Tooltip anchorSelect=".undo" place="top" className='custom-tooltip'>
                Actually, keep this
            </Tooltip>
        </>
    )
}

export default Tooltips
