import { useState } from 'react';
import styled from '@emotion/styled';
// import { IconButton, OutlineButton } from '../global/global_styles';
import { cx, css } from '@emotion/css';

import downImg from '../assets/images/down.svg';
import toc from "../assets/images/toc-16.svg"
import notes from "../assets/images/notebook-24.svg"
import search from "../assets/images/search-16.svg"

const breakpoints = {
  mobile: 360,
  tablet: 768,
  laptop: 1024,
  desktop: 1280
}


export default function ETextContainer() {

    return (
        <>
            <div className='section-title'>
                <h3>Continue reading</h3>
                <div className='actions'>
                    <button className={IconButton} data-tooltip-id="my-tooltip" data-tooltip-content="Archive title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7044 0.888916C13.3856 0.888916 14.0069 1.27815 14.3041 1.8911L15.1111 3.55558V12.6667C15.1111 14.0167 14.0167 15.1111 12.6667 15.1111H3.33336C1.98333 15.1111 0.888916 14.0167 0.888916 12.6667V3.55558L1.69594 1.8911C1.99312 1.27815 2.61442 0.888916 3.29561 0.888916H12.7044ZM13.5556 5.11114H2.44447V12.6667C2.44447 13.1225 2.78762 13.4983 3.2297 13.5496L3.33336 13.5556H12.6667C13.1225 13.5556 13.4983 13.2124 13.5496 12.7704L13.5556 12.6667V5.11114ZM8.00003 6.22225C8.42958 6.22225 8.7778 6.57147 8.7778 7.00226V9.78404L10.2233 8.33942C10.528 8.03481 11.0222 8.03481 11.327 8.33942C11.6318 8.64403 11.6318 9.1379 11.327 9.44252L8.55161 12.2163C8.2468 12.5206 7.75285 12.5205 7.44816 12.216L4.67306 9.44252C4.36827 9.1379 4.36827 8.64403 4.67306 8.33942C4.97785 8.03481 5.47201 8.03481 5.77679 8.33942L7.22225 9.78404V7.00226C7.22225 6.57147 7.57047 6.22225 8.00003 6.22225ZM12.7044 2.44447H3.29561C3.22749 2.44447 3.16416 2.47561 3.12254 2.52729L3.09565 2.56974L2.61767 3.55558H13.3824L12.9044 2.56974C12.8673 2.49313 12.7896 2.44447 12.7044 2.44447Z" fill="#FEFEFE" />
                        </svg>
                    </button>
                    <button className={cx(IconButton,'info')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.55556C4.44083 1.55556 1.55556 4.44083 1.55556 8C1.55556 11.5592 4.44083 14.4444 8 14.4444C11.5592 14.4444 14.4444 11.5592 14.4444 8C14.4444 4.44083 11.5592 1.55556 8 1.55556ZM8.44444 6.22222C8.68991 6.22222 8.88889 6.42121 8.88889 6.66667V12C8.88889 12.2455 8.68991 12.4444 8.44444 12.4444H7.55556C7.3101 12.4444 7.11111 12.2455 7.11111 12V6.66667C7.11111 6.42121 7.3101 6.22222 7.55556 6.22222H8.44444ZM8.44444 3.55556C8.68991 3.55556 8.88889 3.75454 8.88889 4V4.88889C8.88889 5.13435 8.68991 5.33333 8.44444 5.33333H7.55556C7.3101 5.33333 7.11111 5.13435 7.11111 4.88889V4C7.11111 3.75454 7.3101 3.55556 7.55556 3.55556H8.44444Z" fill="#FEFEFE" />
                        </svg>
                    </button>
                </div>
            </div>
            <div>
                <BookDetails>
                    <EtextCover>
                        <img src='https://pearsonux.sfo2.cdn.digitaloceanspaces.com/books-api/955687461bc99ce5f1d23bc2d40e6292.jpeg' />
                    </EtextCover>
                    <GoToPage>
                        <h4>Campbell Biology, 12e</h4>
                        <label>Go to Page</label>
                        <input
                                type="number"
                                value={3}
                                style={{
                                    maxWidth: '200px',
                                    width: '100%'
                                }}
                            />
                         <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="#"
                            // href={`https://pearsonct.design/apps/ereader-web-2024?page=33203940-7457-11ed-83c9-80d153521896`}
                        >
                        <button
                            className={cx(OutlineButton, OpenButton)}
                            style={{
                                maxWidth: '200px',
                                width:'100%'
                            }}
                        >
                            Open eTextbook
                        </button>
                        </a>
                    </GoToPage>
                </BookDetails>
            </div>
        </>
    );
}

const BookDetails = styled.div`
    display: grid;
    grid-template-columns: 160px auto;
    gap: 16px;
    flex:1;
    @media(max-width: ${breakpoints.laptop}px){
        grid-template-columns: 120px auto;
    };
    @media(max-width: ${breakpoints.tablet}px){
        gap:8px;
    };
    button.dropdown {
        color: #FEFEFE;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.33;
        letter-spacing: 0.4px;
        display: flex;
        align-items: center;
        gap: 16px;
        text-aligh: left;
        white-space: nowrap;
    }
`

const EtextCover = styled.div`
  border-radius: 12px;
  background: #272D39;
  width: 160px;
  height: 208px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
  @media(max-width: ${breakpoints.laptop}px){
    width:120px;
    height:156px;
  };
  img{
    width: 144px;
    height: 192px;
    border-radius: 8px;
    background: #D9D9D9;
    @media(max-width: ${breakpoints.laptop}px){
        width:104px;
        height:140px;
    }
  }
`

const GoToPage = styled.div`
  color: white;
  gap: 8px;
  width: 100%;
//   max-width: 300px;
  margin-right: 24px;

  label {
    white-space: nowrap;
    color: #989BA3;
    margin-top:8px;
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 171.429%;
    max-width: 200px;
  }
  input{
    border-radius: 8px;
    border: 2px solid #393F4A;
    background: #020917;
    padding: 12px 8px 12px 16px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    color: #989BA3;
  }
`

const OpenButton = css`
//   @media(max-width:${breakpoints.laptop}px){
    padding: 6px 0;
//   }
`

const EtextCTAs = styled.div`
  flex: 0 1 400px;
  @media(max-width:${breakpoints.laptop}px){
       margin-top:16px;
    }
  ul{
    display: flex;
    flex-direction: column;
    gap: 8px;

    li{
      button{
        width:100%;
        border-radius: 12px;
        background: #272D39;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 16px;
        height: 56px;
        text-align:left;

        span{
          color: #FEFEFE;
          font-size: 18px;
          font-weight: 400;
          white-space:nowrap;
        }
      }
    }
  }
`

export const PageBody= styled.div`
  padding:16px 64px 32px 64px;
  max-width:1664px;
  margin:0 auto;
  @media(max-width: ${breakpoints.laptop}px){
    padding:16px 16px 32px 16px;
  }
  @media(max-width: ${breakpoints.mobile}px){
    padding:8px;
  }
`

export const Modal = styled.div`
    border-radius: 16px;
    background: var(--background-dark-theme-bg-level-1, #161C29);
    box-shadow: 0px 0px 32px 0px #020917;
    color: var(--Text-darkTheme--Default, #FEFEFE);
    padding:0px;
    width: 80vw;
    max-width:960px;
    height: 640px;
    overflow:hidden;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:113;
    display:flex;
    flex-direction:column;

    @media (max-height:660px) {
        min-height:0;
        max-height:80vh;
    }
`

export const Overlay = styled.div`
    width:100vw;
    height:100vh;
    background:black;
    opacity:0.8;
    z-index:112;
    position:absolute;
    top:0;
    left:0;
`

export const OutlineButton = css`
    border-radius: 40px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 6px 24px;
    width:fit-content;
    border: 2px solid var(--Swatch-Light-400, #D2D7E2);
    color: var(--Text-darkTheme--Default, #FEFEFE);
    font-size: 18px;
    line-height:24px;
    font-weight: 700;
    &[disabled]{
        opacity:0.32;
    }
        &:hover {
            border: 2px solid #F7F9FD;
            background-color:#F7F9FD;
            color:#020917;
        }
        &:focus {
            background-color:#F7F9FD;
            border:2px dashed #393F4A;
            color:#020917;
        }
    &.dark {
        border-color:#272D39;
        color:#272D39;
        &:hover {
            background-color:#393F4A;
            border-color:#393F4A;
            color:#fefefe;
        }
        &:focus {
            background-color:#393F4A;
            border:2px dashed #DDE3EE;
            color:#fefefe;
        }
    }

`

export const FillButton = css`
    border-radius: 40px;
    background: var(--Swatch-Light-400, #D2D7E2);
    border:2px solid transparent;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
    width:fit-content;
    color: #020917;
    font-size: 18px;
    line-height:24px;
    font-weight: 700;
    &[disabled]{
        opacity:0.32;
    }
        &:focus {
            background-color:#F7F9FD;
            border:2px dashed #DDE3EE;
        }

    &.dark {
        border-color:#272D39;
        background-color:#272D39;
        color:#fefefe;
        &:hover {
            background-color:#393F4A;
            border-color:#393F4A;
            color:#fefefe;
        }
        &:focus {
            background-color:#393F4A;
            border:2px dashed #DDE3EE;
        }
    }
`
export const SmallButton = css`
    font-size:14px;
    line-height:16px;
    padding: 8px 16px;
`

export const PrimaryButton = css`
    border-radius: 40px;
    background: var(--Gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
    color: var(--Text-darkTheme--Default, #FEFEFE);
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
    width:fit-content;
    height:40px;
    font-size: 18px;
    font-weight: 700;
    white-space:nowrap;

    &[disabled]{
        opacity:0.32;
    }
        &:hover {
            background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
        }
`
export const LinkButton = css`
    border:none;
    background:transparent;
    color: #655591;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: underline;
    &:hover, &:focus {
        text-decoration:none;
    }
`
export const IconButton = css`
    width:40px;
    height:40px;
    border-radius:50%;
    padding:10px;
    background: transparent;
    color:#fefefe;
    border:2px solid transparent;
    display:flex;
    align-items:center;
    justify-content:center;

    &:hover {
        background:#272D39;
    }
    &:active {
        border: 2px dashed #DDE3EE;
    }
`
export const ModalHeader = styled.h1`
  width:100%;
  text-align:left;
  font-size: 40px;
  font-weight: 700;
  padding:40px 40px 24px 40px;
  margin:0;
`

export const ModalActions = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-end;
  gap:16px;
  width:100%;
  padding: 24px 16px;
`
export const Tooltip = css`
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    background-color:#fefefe;
    color:#020917;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
`
