/** @format */

import React, { useEffect, useState, useRef } from 'react';
import styled  from '@emotion/styled';
import { css } from "@emotion/css";
import {colors} from "../styles/colors"

import { useNavigate } from "react-router-dom";

import close from "../assets/images/close.svg";

import uploadIcon from "../assets/images/upload-26.svg";
import dropToUploadIcon from "../assets/images/drop-to-upload.svg";
import paperClipIcon from "../assets/images/paper-clip.svg";


const UploadNotes = (props) => {

  const navigate = useNavigate();

  const showProcessingModal = props.showProcessingModal;
  const setShowProcessingModal = props.setShowProcessingModal;

  const inputRef = useRef();

  const [inputValue, setInputValue] = useState('');

  // For displaying the translated image
  const translatedImage = props.translatedImage;
  const setTranslatedImage = props.setTranslatedImage;

  /**display text on textarea */
  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (typeof props.onChange === 'function') {
      props.onChange(e.target.value);
    }
  };

  const [dragging, setDragging] = useState(false);

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
    setDragging(true);
  }

  const handleDragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragging(false);
  }

  const handleDragEnd = (e) => {
    setDragging(false);
  }

  const detachImage = (e) => {
    setTranslatedImage(null);
  }


  const handleDrop = (e) => {
    e.preventDefault(); // Keeps file path from being added to textbox
    e.stopPropagation();

    if (e.type === 'drop') {
      let dt = e.dataTransfer;
      let files = dt.files;
      let file = files[0];

      setDragging(false);
      console.debug("THIS WAS A DROP");
      console.debug(file);

      handleProcessing();
    } else {
      // setUploadImageSource(URL.createObjectURL(e.target.files[0]));
      inputRef.current.value = '';
      console.debug("THIS WAS A FILE SELECTION");
      console.debug(e.target.files[0]);

      handleProcessing();
    }
  }

  const handleProcessing = () => {
    console.debug("GOING HANDLEPROCESSING");
    setShowProcessingModal(true);
    setTimeout(() => {
      console.log('World!');
      navigate(`${process.env.PUBLIC_URL}/review`);

    }, 3000);


  }
  /**Submit Icon button */
  const handleSubmit = () => {
    props.handleNewQuestion(inputValue)
    setInputValue(''); //clear text when submit
  };


  return (
    <>
      <Container
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragEnd}
        onDrop={handleDrop}
        className= {`${dragging == true ? 'dragging' : ''}`}
      >

        <img src={dropToUploadIcon} />
        <p>Drop your notes here and we’ll review them.</p>

        <div className={actions}>

          {/*Hidden input controller*/}
          <input
            type="file"
            tabindex="-1"
            ref={inputRef}
            // style={{display: 'none'}}
            accept="application/pdf, .txt"
            onChange={handleDrop}
          />

          <div className="subactions">
            <button type="button" className={actionBtn} onClick={()=>{
              inputRef.current.click()}
            }><img src={paperClipIcon} className={paperClip} /></button>



            <div className={divider}></div>

            <GradientIconButton onClick={handleSubmit} disabled={inputValue == '' && translatedImage == null}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M1 21V3L23 12L1 21ZM3.31579 17.625L17.0368 12L3.31579 6.375V10.3125L10.2632 12L3.31579 13.6875V17.625Z"
                  fill="#FEFEFE"
                />
              </svg>
            </GradientIconButton>
          </div>
        </div>
      </Container>


      </>
  );
};

export default UploadNotes;

const Container = styled.div`
  margin: 0 24px 0 24px;
  padding:24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  position: relative;
  border: 2px solid #DDE3EE;
  border-radius: 8px;

  p {
    margin: 16px 0 0;
    color:${colors.textlightThemeDefault};
    font-size: 1.125rem;
    line-height: 1.5;
  }

  img {
    pointer-events: none;
  }

  &.dragging {
    border: 2px dashed #DADEEB;
    background-color: #E7EAF9;

  }
`;

const attachedImageContainer = css`
  margin: 0;
  padding: 0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const zoomBtn = css`
  margin: 0;
  padding: 0;
  display: block;
  border-radius: 10px;
  overflow: hidden;
  background-color: #D6D6D6;
`;

const attachedImage = css`
  display: block;
  min-width: 220px;
  max-width: 440px;
  min-height: 175px;
  max-height: 345px;
  object-fit: scale-down;
`;

const detachImageButton = css`
    margin: 0;
    padding: 0;
    position:absolute;
    top:4px;
    right:4px;
    background-color: #C7CCD6;
    border-radius: 40px;

    img {
      display: block;
    }
`;

const paperClip = css`
  width: 26px;
  height: auto;
`;

const GradientIconButton = styled.button`
  margin-left: 11px;
  margin-top: 0;
  margin-right: 0;
  align-self: flex-end;
  border-radius: 40px;
  padding: 8px;
  background: var(
    --Gradient-01,
    linear-gradient(135deg, #e49d2d 0%, #ea0b7e 51.09%, #851b4a 100%)
  );
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: auto;
    opacity: 0.32;
  }
`;


const helpText = css`
    margin: 8px 24px 0;
    padding: 0;
    color:${colors.darkGray};

    &.dragging {
      opacity: 0.5;
    }

    a {
      color: #655591;
      text-decoration: underline;
    }
`;

const actions = css`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3px;

  .subactions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  img {
    display: block;
  }
`;

const divider = css`
    margin: 0 6px;
    padding: 0;
    align-self: stretch;
    border-left: 1px solid #B4B7B7;
  `;

const actionBtn = css`
  display: block;
  margin: 0;
  padding: 6px;
  border-radius: 100%;

  &:hover {
    background-color: #ECEBEF;
  }
`;
