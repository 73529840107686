import styled from '@emotion/styled'
import { css } from '@emotion/css'

import { useNavigate } from 'react-router-dom'

import dropDownIcon from "../assets/images/chevron.svg"
import linkIcon from '../assets/images/link-out-16.svg';
import { useState } from 'react'

export default function AccordionCardInner() {
    const [open, setOpen] = useState(true)

    const navigate = useNavigate();

    return (
        <div>
            <AccordionButton
            // onClick={() => setOpen(!open)} aria-expanded={open}
            >
                <h3>Study & Exam Prep Pack</h3>
                 <img src={dropDownIcon} />
            </AccordionButton>
            {open &&
                <AccordionBody>
                    <ul>
                          <li>
                            <a href="#">
                                <span>Learn</span>
                                <img src={linkIcon} alt=""/>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                                <span>Exam prep</span>
                                <img src={linkIcon} alt=""/>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                                <span>AI Tutor</span><img src={linkIcon} alt=""/></a>
                          </li>
                          <li>
                            <a href="#"><span>Explore</span><img src={linkIcon} alt=""/></a>
                          </li>
                        </ul>
                </AccordionBody>
            }
        </div>
    );
}

const AccordionButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:space-between;
    text-align:left;
    padding:0;
    width:100%;
    background:transparent;
    border:none;
    h3 {
        margin:0 !important;
    }
    img {
        transition:all 0.3 ease;
    }
    &[aria-expanded=true] {
        img {
            transform:rotate(180deg);
        }
    }
`
const AccordionBody = styled.div`
    padding-top:24px;
  ul{
    list-style-type: none;
    li a{
        font-size:18px;
        line-height:24px;
        padding: 12px 16px;
        padding-right:40px;
        color:#fefefe;
        display:block;
        position:relative;
        border-radius:12px;

        img {opacity:0;transition:opacity 0.3s ease;position:absolute;
            right:16px; top:calc(50% - 8px);
        }
      &:hover {
        background-color:#272D39;
        img {opacity:1;}

        &:after {
            background: linear-gradient(135deg, #c08529, #b41863 51.09%, #851b4a) border-box;
            border: 2px solid transparent;
            border-radius: inherit;
            border-radius: 12px;
            bottom: 0;
            content: "";
            left: 0;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            position: absolute;
            right: 0;
            top: 0;
            z-index:0;
        }
      }
  }
`
