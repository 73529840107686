/** @format */
import EditNotes from "../components/EditNotes";
import Tooltips from "../components/Tooltips";


const Review = (props) => {

  return (
    <>
    <Tooltips/>
    <EditNotes/>
    </>
  );
}
export default Review;
