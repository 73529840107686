import { useEffect, useState } from 'react';
import styled from '@emotion/styled'
// import { Link, useParams, useLocation, useSearchParams, useNavigate, createSearchParams } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
// import type { RootState } from '../../redux/store'
// import { addAllCourses } from '../../redux/courseSlice';
// import { resetMyCourses } from '../../redux/courseSlice';
// import { Course } from '../../global/global_types';
// import { breakpoints } from '../../global/global_breakpoints';

const breakpoints = {
  mobile: 360,
  tablet: 768,
  laptop: 1024,
  desktop: 1280
}


export default function Navigation() {

    // const { courseId } = useParams<{ courseId?: string }>()

    // const location = useLocation()
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const [searchParams, setSearchParams] = useSearchParams();

    // const selectedCourse = useSelector((state: RootState) => state.selectedCourse)[0]
    // const courses = useSelector((state: RootState) => state.courses)
    // const availableCourses = useSelector((state: RootState) => state.availableCourses)

    // const [showAllCourses, setShowAllCourses] = useState<boolean>(false)
    // const [sortedCourses, setSortedCourses] = useState<Course[]>([])


    // useEffect(() => {
    //     if (searchParams.get('showAllCourses') === 'true') {
    //         setShowAllCourses(true)
    //     }
    // }, [searchParams, availableCourses])

    // useEffect(()=>{
    //     let newCourses = [...courses]
    //     newCourses.sort((a, b) => a.course_name.localeCompare(b.course_name))
    //     setSortedCourses(newCourses)
    // },[courses])


    return (
        <NavContainer
            data-tour-step="2"
            // showAllCourses={showAllCourses}
        >
            <NavButton
                className="add-course"
                onClick={() => {
                    // navigate(`${process.env.PUBLIC_URL}/course/0?addetext=true`)
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.51483 10.8122C2.85899 10.8122 2.32733 11.3438 2.32733 11.9997C2.32733 12.6555 2.85899 13.1872 3.51483 13.1872H10.8126V20.485C10.8126 21.1408 11.3443 21.6725 12.0001 21.6725C12.656 21.6725 13.1876 21.1408 13.1876 20.485V13.1872H20.4854C21.1412 13.1872 21.6729 12.6555 21.6729 11.9997C21.6729 11.3438 21.1412 10.8122 20.4854 10.8122H13.1876V3.5144C13.1876 2.85857 12.656 2.3269 12.0001 2.3269C11.3443 2.3269 10.8126 2.85857 10.8126 3.5144V10.8122H3.51483Z" fill="#FEFEFE" />
                </svg>
                <span>Add a course</span>
            </NavButton>
            <NavButton
                  // key={index}
                  className={'selected'}
                  // onClick={() => {
                  //     navigate({
                  //         pathname: `${process.env.PUBLIC_URL}/course/${course.course_id}`,
                  //         search: showAllCourses ? createSearchParams({
                  //             showAllCourses: "true"
                  //         }).toString() : ""
                  //     })
                  // }}
              >
                  <span className='book-img'>
                      <img src='https://pearsonux.sfo2.cdn.digitaloceanspaces.com/books-api/955687461bc99ce5f1d23bc2d40e6292.jpeg'/>
                  </span>
                  General Biology
              </NavButton>
        </NavContainer>
    );
}

const NavContainer = styled.div`
    width: unset;
    overflow-x: auto;
    display: inline-flex;
    align-items:center;
    gap: 16px;
    padding-right: unset;
`

const NavButton = styled.button`
    flex-shrink: 0;
    padding: 0 16px;
    height: 72px;
    border-radius: 12px;
    background: #272D39;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FEFEFE;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.4px;
    gap: 12px;
    box-sizing: border-box;
    border: 2px solid transparent;
    width:unset;
    &.selected{
        background-color: #E8EEFA;
        color: #020917;
        &:hover{
            background: linear-gradient(#E8EEFA, #E8EEFA) padding-box, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%) border-box;
        }
    }
    &:hover{
        background: linear-gradient(#272D39, #272D39) padding-box, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%) border-box;
    }
    &.add-course {
        gap: 30px;
        span{
            @media(max-width: ${breakpoints.tablet}px){
                display:none;
            }
        }
    }
    .book-img {
        margin: 4px;
        border-radius: 8px;
        width: 48px;
        box-sizing: border-box;
        background: #fff;
        height: 64px;
        margin-left: -12px;
        overflow:hidden;
        img{
            width: 48px;
            height: 64px;
            object-fit:cover;
        }
    }
`

// const Courses = styled.div`
//     display:flex;
//     align-items:center;
//     gap: 16px;
// `

