import { useEffect, useState, useRef, createRef } from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux';
// import type { RootState } from '../redux/store'
// import { setSelectedChannelTopic } from "../redux/selectedChannelTopicSlice";
// import { ChannelTopic } from '../global/global_types';
// import { ChannelAsset } from '../global/global_types';
// import { getAssetDetails } from '../util/getAssetDetails';


export default function TopicSelector() {

    // const dispatch = useDispatch();

    // const selectedChannelTopic = useSelector((state: RootState) => state.selectedChannelTopic)
    // const channelData = useSelector((state: RootState) => state.channelData)

    // const [showMenu, setShowMenu] = useState<boolean>(false)
    // const [accordionButtons, setAccordionButtons] = useState<any>(null)
    // const [accordionPanels, setAccordionPanels] = useState<any>(null)

    // useEffect(() => {
    //     if (channelData && channelData.toc) {
    //         setAccordionButtons(channelData.toc.map(() => createRef<any>()))
    //         setAccordionPanels(channelData.toc.map(() => createRef<any>()))
    //     }
    // }, [channelData])

    // const handleAccordion = (i: number) => {
    //     let button = accordionButtons[i].current
    //     let panel = accordionPanels[i].current
    //     if (panel.getAttribute('hidden') === '') {
    //         button.setAttribute('aria-expanded', 'true')
    //         panel.removeAttribute('hidden')
    //     } else {
    //         button.setAttribute('aria-expanded', 'false')
    //         panel.setAttribute('hidden', '')
    //     }
    // }

    // const handleMenu = () => {
    //     setShowMenu(!showMenu)
    // }

    // const handleSelect = (topic: ChannelTopic) => {
    //     dispatch(setSelectedChannelTopic(topic))
    //     setShowMenu(false)
    // }

    return (
        <SectionSelectorContainer>
            <CustomSelect>
                {/* <label
                    htmlFor="table-of-contents"
                    className='sr-only'
                >
                    Select a topic
                </label> */}
                <CustomSelectButton
                    // onClick={handleMenu}
                    id="table-of-contents"
                >
                    <CustomSelectValue>Chapter 1: Evolution, the Themes of Biology, and Scientific Inquiry</CustomSelectValue>
                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#989BA3"></path>
                    </svg>
                </CustomSelectButton>
            </CustomSelect>

        </SectionSelectorContainer>
    )


}

const SectionSelectorContainer = styled.div`
    position:relative;
    margin-top:16px;
    margin-bottom:16px;
`

const CustomSelect = styled.div`

`

const CustomSelectLabel = styled.label`
    display:block;
    color:white;
`

const CustomSelectButton = styled.button`
    border-radius: 8px;
    background: #161C29;
    border: 2px solid #393F4A;
    color: #989BA3;
    width: 100%;
    cursor: pointer;
    display: flex;
    padding: 16px 12px;
    align-items: center;
    justify-content: space-between;
    font-size:20px;
    svg{
        width: 24px;
        height: 24px;
        transform: rotate(90deg);
    }
`

const CustomSelectValue = styled.span`
    text-align:left;
    // font-family: "TT Commons";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Accordion = styled.ul`
    position:absolute;
    top:00px;
    z-index:3;
    width:100%;
    max-height:300px;
    overflow:auto;
    border-radius: 8px;
    border: 0px solid var(--background-dark-theme-bg-level-1, #161C29);
    background: var(--background-dark-theme-bg-level-2, #272D39);
    box-shadow: 0px 0px 12px 0px #020917;
`

const CurrentSelected = styled.button`
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items:center;
    justify-content:space-between;
    position: relative;
    flex-direction: row;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: var(--Text-darkTheme--Muted, #989BA3);
    background: var(--background-dark-theme-bg-level-2, #272D39);
    padding:16px;
    border-radius:8px;
    position:sticky;
    top:0;
    left:0;
    z-index:4;
    p{
        margin:0;
    }
    button{
        text-align:left;
    }
    svg{
        width:24px;
        height:24px;
        transform:rotate(270deg);
    }
`

const AccordionGroup = styled.li`
    margin-bottom: 8px;
    border-radius: 8px;
    margin-top: 8px;
`

const AccordionButton = styled.button`
    width: calc(100% - 16px);
    cursor: pointer;
    display: flex;
    align-items:center;
    gap:8px;
    position: relative;
    flex-direction: row;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: var(--Text-darkTheme--Default, #FEFEFE);
    padding:8px;
    margin: 0 8px;
    border-radius:8px;
    text-align:left;
    p{
        margin:0;
    }
    // button{

    // }
    svg{
      width:24px;
      height:24px;
      transform:rotate(90deg);
    }
    &:hover{
        background-color: #161C29;
    }
    &[aria-expanded=true]{
        svg{
            transform:rotate(270deg);
            transition: all 300ms ease;
        }
    }
`

const AccordionPanel = styled.div`
    overflow: auto;
    // padding: 0 0 0 20px;
    button{
        display:block;
        &.section-button{
            padding:8px;
            padding-left:46px;
            margin: 0 8px;
            width:calc(100% - 16px);
            text-align:left;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            color: var(--Text-darkTheme--Default, #FEFEFE);
            border-radius:8px;
            &:hover{
                background-color: #161C29;
            }
        }
    }
`
