// import { useEffect, useState, createRef } from 'react';
// import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled';
// import { Link } from 'react-router-dom';
// import { PrimaryButton } from "../global/global_styles"
// import SectionSelector from './SectionSelector';
import TopicSelector from './TopicSelector';
// import { ChannelAsset, Course } from '../global/global_types';
// import { getAssetDetails } from '../util/getAssetDetails';
// import { getChannelTOCItem } from '../util/getChannelTOCItem';
// import { breakpoints } from '../global/global_breakpoints';
import { cx, css } from '@emotion/css'
import {channelAssets} from "../data/channel_assets"
import { Link } from 'react-router-dom';

import problemImage from "../assets/images/practiceSetIcon.avif"

export default function ChannelAssets() {

    const calculateDuration = (totalSeconds) => {
        let minutes = Math.floor(totalSeconds / 60)
        let seconds = (totalSeconds - minutes * 60).toString()
        if (seconds.length == 1) {
            seconds = '0' + seconds
        }
        return `${minutes}:${seconds} min`
    }

    const getTypeColor = (type) => {
        switch(type){
          case "Concept" :
            return '#31B9E1'
            break;
          case "Intro" :
            return '#31B9E1'
            break;
          case "Example" :
            return '#F888AE'
            break;
          case "Problem" :
            return '#0CC0AD'
            break
          default:
            return 'white'
        }
      }

    return (
        <ChannelAssetsSectionContainer>
            <div className="header">
                <h3>Study & Exam Prep Pack</h3>
                <div>
                  <button
                      className={PrimaryButton}
                  >
                      Open study tools
                  </button>
                </div>
            </div>
            {/* {
              course.channel && !course.textbook ? */}
                <TopicSelector />
            {/* //     :
            //     <SectionSelector/>
            // } */}
            <AssetsContainer>
            <Assets>
                {
                    channelAssets && channelAssets.length > 0 ?
                        channelAssets.map((asset, index) => {
                            return (
                                <VideoButton
                                    key={index}
                                    // onClick={()=>{
                                    //   videoLinkButtons[index].current.click()
                                    // }}
                                >
                                    <div>
                                        <span>{index + 1}</span>
                                        <div>
                                            <p className='type' style={{color:`${getTypeColor(asset.type)}`}}>{asset.type}</p>
                                            {
                                                asset.type != 'Problem' ?
                                                <p className="title">{asset.title}</p>
                                                : <>
                                                {
                                                    typeof asset.question === 'string' ?
                                                    <p className="title">{asset.question}</p>
                                                    :
                                                    <p className="title">{asset.question.text}</p>
                                                }
                                                </>
                                            }
                                            {
                                                asset.duration ?
                                                    <p className='meta'>{calculateDuration(asset.duration)}</p>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <img src={
                                            asset.thumbnailUrl ? asset.thumbnailUrl :
                                            asset.type === 'Problem' ? problemImage : ''} />
                                    {/* <Link
                                        className='sr-only'
                                        // ref={videoLinkButtons[index]}
                                        // to={`https://pearsonct.design/apps/channels-2024/learn/65ae95660e075b6be10f9cf5?chapter=j9VUT5&section=${sectionSid}&asset=asset-${asset._id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        go to channel
                                    </Link> */}
                                </VideoButton>
                            )
                        })
                        : null
                }
                <VerticalRule/>
            </Assets>
            </AssetsContainer>
        </ChannelAssetsSectionContainer>
    )
}

const ChannelAssetsSectionContainer = styled.div`
container-type: inline-size;
.header{
  display:flex;
  align-items:center;
  justify-content:space-between;
  container-type: inline-size;
}
@container(width < 540px){
  .header{
    display:block;
    width:100%;
    h3{
      margin-bottom:8px;
    }
    &>div{
      display:flex;
      justify-content:right;
    };
  }
`


const AssetsContainer = styled.div`
    max-height:320px;
    overflow:auto;
    position:relative;
`

// const ChannelAssetsHeader = styled.div`
  //`

const Assets = styled.div`
  position:relative;
  height:100%;
`

const VideoButton = styled.div`
  z-index:2;
  border-radius: 12px;
  background: #272D39;
  color: #FEFEFE;
  width: 100%;
  min-height: 64px;
  color: black;
  text-align:left;
  margin-bottom:16px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  gap:16px;
  padding: 8px 16px;
  cursor:pointer;
  position:relative;
  border: 2px solid transparent;
  &:hover{
    background: linear-gradient(#272D39, #272D39) padding-box, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%) border-box;
  }
  span {
    color: #FEFEFE;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 88.889%;
  }

  &>div{
    display:flex;
    align-items:center;
    gap: 16px;
  }
  p {
    margin: 0;
    color: #FEFEFE;

    &.type {
        color: #31B9E1;
        text-transform: uppercase;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 171.429%;
    }
    &.title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.4px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    &.meta {
        color: #989BA3;font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: 0.24px;
    }
  }
  img{
    border-radius: 8px;
    width: 96px;
    height: 64px;
    object-fit: cover;
  }
`

const VerticalRule = styled.span`
  display:block;
  position:absolute;
  background: #272D39;
  width: 2px;
  // height:calc(100% - 100px);
  height:100%;
  position:absolute;
  left:40px;
  top:0;
  z-index:1;
`

export const PrimaryButton = css`
    border-radius: 40px;
    background: var(--Gradient-01, linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%));
    color: var(--Text-darkTheme--Default, #FEFEFE);
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
    width:fit-content;
    height:40px;
    font-size: 18px;
    font-weight: 700;
    white-space:nowrap;

    &[disabled]{
        opacity:0.32;
    }
        &:hover {
            background: linear-gradient(135deg, #C08529 0%, #B41863 51.09%, #851B4A 100%);
        }
`
